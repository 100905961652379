import { flatten } from 'lodash';
import { Link, withRouter } from 'react-router-dom';
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import log from 'loglevel';
import { Button, Col, Row } from '@swift-paypal/pp-react';
import PropTypes from 'prop-types';
import resolve from '../../services/route.service';
import Title from '../common/title';
import { Alert, AlertTypes } from '../common/alert';
import fileValidationService from '../../lib/document-upload-validation-utils';
import { scrollToTop } from '../../services/window.service';
import withUrlParams from '../../hocs/with-url-params';
import UploadSingleDocumentComponent from '../common/upload-single-document/upload-single-document';
import {
  allowedFileTypes,
  allowedSingleFileSize,
  stipulationSpecificValidations,
} from '../../constants/upload-document';
import errors from '../../constants/errors';
import routes from '../../routes';

class UploadMultipleDocumentsComponent extends Component {
  static propTypes = {
    country: PropTypes.string,
    downloadSection: PropTypes.element,
    groupDescription: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    minimumStipulationsRequired: PropTypes.number,
    showPPPLoan: PropTypes.bool,
    submit: PropTypes.func.isRequired,
    stipulations: PropTypes.arrayOf(PropTypes.object).isRequired,
    uploadPending: PropTypes.bool,
    uploadError: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
    ]),
    response: PropTypes.shape({
      params: PropTypes.object,
    }),
  };

  static defaultProps = {
    country: undefined,
    groupDescription: undefined,
    minimumStipulationsRequired: undefined,
    showPPPLoan: undefined,
    uploadError: false,
    uploadPending: false,
    response: undefined,
    downloadSection: undefined,
  };

  constructor(props) {
    super(props);
    const { stipulations } = this.props;

    this.state = {
      stagedFiles: stipulations.map((stip, i) => ({
        expanded: !stip.isFulfilled && i === 0,
        fileBlob: undefined,
        isValid: stip.isFulfilled ? true : undefined,
        errors: [],
      })),
      submitted: false,
    };
  }

  componentDidUpdate() {
    const { stipulations } = this.props;
    const { stagedFiles } = this.state;
    if (stagedFiles.length < stipulations.length) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        stagedFiles: stipulations.map((stip, i) => ({
          expanded: !stip.isFulfilled && i === 0,
          fileBlob: undefined,
          isValid: stip.isFulfilled ? true : undefined,
          errors: [],
        })),
      });
    }
  }

  onDrop = (i) => (file) => {
    const { stipulations } = this.props;
    const { stagedFiles } = this.state;
    const { type } = stipulations[i];
    file.errors = fileValidationService.getFilesErrors([file.fileBlob], stipulationSpecificValidations[type] || null);

    if (!file.errors.length) {
      file.isValid = true;
      if (stagedFiles[i + 1] && !stagedFiles[i + 1].expanded && !stagedFiles[i + 1].fileBlob && !stipulations[i + 1].isFulfilled) {
        stagedFiles[i + 1].expanded = true;
      }
      else {
        const collapsed = stagedFiles.filter((f) => !f.expanded && !f.fileBlob);

        if (collapsed.length) {
          const collapsedIndex = stagedFiles.indexOf(collapsed[0]);
          if (!stipulations[collapsedIndex].isFulfilled) {
            stagedFiles[collapsedIndex].expanded = true;
          }
        }
      }
    }
    else {
      log.error(`fileError: ${file.errors}`);
    }
    stagedFiles[i] = file;
    this.setState({ stagedFiles, submitted: false });
  };

  removeFile = (i) => (file) => {
    const { stagedFiles } = this.state;
    stagedFiles[i] = file;
    this.setState({ stagedFiles, submitted: false });
  };

  showHide = (i) => () => {
    const { stagedFiles } = this.state;
    const { stipulations } = this.props;
    if (!stipulations[i].isFulfilled) {
      stagedFiles[i].expanded = !stagedFiles[i].expanded;
      this.setState({ stagedFiles });
    }
  };

  submit = () => {
    const { submit: submitFunc } = this.props;
    const { stagedFiles } = this.state;
    const checkErrorsAndScroll = () => {
      if (this.shouldShowAlert()) {
        scrollToTop();
      }
    };
    this.setState({ submitted: true }, checkErrorsAndScroll);

    const errorsPresent = stagedFiles.find((file) => file.errors.length);
    if (errorsPresent) {
      return;
    }

    const emptyFilesPresent = this.hasEmptyFiles(stagedFiles);
    if (emptyFilesPresent) {
      return;
    }

    submitFunc(stagedFiles);
  };

  hasEmptyFiles = (stagedFiles) => {
    const { minimumStipulationsRequired, stipulations } = this.props;
    const minimumRequired = minimumStipulationsRequired || stagedFiles.length;
    const stipulationsFulfilled = stagedFiles.filter((file, i) => {
      const stip = stipulations[i];
      return stip.isFulfilled || !!file.fileBlob;
    });
    return stipulationsFulfilled.length < minimumRequired;
  };

  mapUploadComponents = () => {
    const { stipulations } = this.props;
    const { stagedFiles } = this.state;
    if (stipulations.length !== stagedFiles.length) return null;

    return stipulations.map((stipulation, i) => {
      const allowedFileTypesResolved = stipulationSpecificValidations[stipulation.type]
        ? stipulationSpecificValidations[stipulation.type].allowedFileTypes : allowedFileTypes;
      const allowedFileTypesFlatteneded = flatten(Object.values(allowedFileTypesResolved));
      const showBorderTop = i === 0 && !stagedFiles[i].expanded ? 'show-border-top' : '';
      const showBorderBottom = !(stagedFiles[i] && stagedFiles[i].expanded)
        && !(stagedFiles[i + 1] && stagedFiles[i + 1].expanded) ? 'show-border-bottom' : '';

      if (!stipulation || !stagedFiles[i]) {
        return null;
      }

      return (
        <div key={stipulation.id} className="upload-multuple-documents-container">
          <UploadSingleDocumentComponent
            allowedFileSize={allowedSingleFileSize}
            allowedFileTypes={allowedFileTypesFlatteneded}
            showBorderTop={showBorderTop}
            showBorderBottom={showBorderBottom}
            stipulation={stipulation}
            onDrop={this.onDrop(i)}
            showHide={this.showHide(i)}
            removeFile={this.removeFile(i)}
            fileStaged={stagedFiles[i]}
          />
        </div>
      );
    });
  };

  shouldShowAlert = () => {
    const { uploadError, uploadPending, response } = this.props;
    const { stagedFiles, submitted } = this.state;
    const errorsPresent = stagedFiles.find((file) => file.errors.length);
    const emptyFilesPresent = this.hasEmptyFiles(stagedFiles);
    const showServerError = uploadPending === false && !!uploadError && submitted;
    if (uploadError) {
      log.error(`response: ${response} uploadError: ${uploadError}`);
    }
    return (errorsPresent || emptyFilesPresent || showServerError) && submitted;
  };

  render() {
    const {
      country, uploadError, uploadPending, groupDescription, downloadSection, showPPPLoan,
    } = this.props;
    const { submitted } = this.state;

    const showServerError = uploadPending === false && !!uploadError && submitted;
    return (
      <>
        {!showPPPLoan && <Title text="Upload documents" />}
        <div>
          {/* {Group description} */}
          {groupDescription && <div className="secondary-gray margin-bottom-sm">{groupDescription}</div>}
          {downloadSection}

          {/* {Generic error} */}
          {showServerError && (
            <Alert
              message={errors.technicalIssue}
              alertType={AlertTypes.critical}
            />
          )}
          {this.shouldShowAlert() && !showServerError && (
            <Alert
              message={errors.uploads.uploadAllMessage}
              alertType={AlertTypes.critical}
            />
          )}

          {/* {File upload areas} */}
          {this.mapUploadComponents()}

          {/* {Upload button} */}
          <Row className="margin-top-sm">
            <Col xs={6} sm={5} md={4}>
              <Button type="submit" className="pp-react__button--block" onClick={this.submit}>
                Submit
              </Button>
            </Col>
            <Col xs={6} sm={5} md={4}>
              <Button secondary as={Link} to={resolve(routes.summary.path, { country })} type="button" className="pp-react__button--block">
                Cancel
              </Button>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default compose(
  connect(),
  withRouter,
  withUrlParams,
)(UploadMultipleDocumentsComponent);
