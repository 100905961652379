export default {
  ownership: {
    addApplicantAsOwner: (customerSupportPhone) => `If you would like to add yourself as a business owner, please call your Business Financing Expert or contact sales at ${customerSupportPhone}`,
    addApplicantAsManager: (customerSupportPhone) => `If you would like to add yourself as the business manager, please call your Business Financing Expert or contact sales at ${customerSupportPhone}`,
    addOwnerOverLimit: 'Cannot add owner that would make total percent greater than 100%',
    addOwnerUnderMinimum: 'Cannot add a business owner who owns less than 25%',
    addSbaOwnerUnderMinimum: 'Cannot add a business owner who owns less than 20%',
    addControllingManagerUnderMinimum: 'Cannot add a business owner who owns less than 0%',
    addAdditionalSignerUnderMinimum: 'Cannot add a signer who owns less than 1%',
    editSameControllingManager: 'Cannot edit manager. Finish editing in business owner section to continue editing.',
    editSameBusinessOwner: 'Cannot edit business owner. Finish editing in manager section to continue editing.',
    deleteAdditionalSigners: 'Cannot remove signer while editing.',
    deleteBusinessOwner: 'Cannot remove business owner while editing.',
    deleteControllingManager: 'Cannot remove manager while editing.',
    duplicateEmail: 'Owner exists with same Email',
    duplicateSsn: 'Owner exists with same SSN',
    duplicateSin: 'Owner exists with same SIN',
    agreeTermsAndConditions: 'You must agree to the Privacy Policy and Terms and Conditions.',
    agreeOwnershipInformation: 'You must attest to the accuracy of the ownership information.',
    addControllingManager: 'You must select one individual to indicate the Business Manager.',
    tooManySigners: (customerSupportPhone) => `If you require more than 4 signers, please call your business financing expert or ${customerSupportPhone}.`,
    titleLength: 'Job title must be at most 128 characters in length.',
  },
  requiredField: 'Required',
  invalidPhone: 'Invalid phone number',
  invalidEmail: 'Invalid email address',
  invalidBirthDateFormat: 'Date of birth must be in the valid format',
  invalidBirthDate: 'Please enter a date between 01/01/1900 and today\'s date',
  invalidCanadianBirthDate: 'Please enter a date between 1900/01/01 and today\'s date',
  invalidDateFormat: 'Date must be in the valid format (mm/dd/yyyy)',
  invalidFirstDateAfterPppFunding: 'Must be a date after PPP funding',
  invalidPostalCode: 'Postal Code must be a valid format',
  invalidSsn: 'Social Security number must be in the valid format',
  invalidSin: 'Social Insurance number must be in the valid format',
  emailsMustMatch: 'The email addresses must match',
  emailLength: 'Email address must be no longer than 75 characters',
  unauthorized: 'Unauthorized',
  firstNameLength: 'First name must be at most 100 characters in length.',
  lastNameLength: 'Last name must be at most 100 characters in length.',
  businessFirstNameLength: 'First name must be at most 40 characters in length.',
  businessLastNameLength: 'Last name must be at most 50 characters in length.',
  dataMissing: 'We are experiencing a technical issue, please try refreshing the page or contact customer support',
  invalidBankAccount: 'Account number must be between 4 and 17 digits long.',
  invalidBankAccountRepeating: 'Please enter a valid account number.',
  invalidBankAccountSequential: 'Please enter a valid account number.',
  invalidBranchTransitNumber: 'Branch transit number must be 5 digits.',
  invalidFinancialInstitutionAccountNumber: 'Financial institution account number must be between 1 and 12 digits.',
  invalidFinancialInstitutionNumber: 'Financial institution number must be 3 digits.',
  uploads: {
    fileTooBig: 'File must be under 24MB.',
    fileTooSmall: 'File must be larger than 0MB.',
    filesTooBig: 'Total size of your files must not exceed 375MB.',
    tooManyFiles: 'You can upload up to 15 files at a time.',
    fileTypeIsNotAllowed: 'File type must be a .docx, .xslx, .pdf, .jpg, .jpeg, .png, .tiff.',
    fileTypeIsNotAllowedTemplate: 'File type must be a {fileTypes}.',
    fileNameIsNotAllowed: 'File name may not contain any of the following special characters (:|></\\).',
    fileDoesNotExist: 'File does not exist or has been moved.',
    fileFailedToUpload: 'failed to upload file. If this issue persists, please contact Customer Service.',
    uploadAllMessage: 'Please upload all required documents before submitting.',
  },
  technicalIssue: 'We are experiencing a technical issue, please contact customer support',
  authorization: 'Authorization failed',
  loanAgreementWarning: 'There was a problem with your loan agreement. Please print your loan \nagreement, sign and upload again. Be sure to check your document to make \nsure all fields are signed and legible.',
  resetError: (customerSupportPhone) => `There was a problem resetting your account. Please contact customer support at ${customerSupportPhone}.`,
  createError: (customerSupportPhone) => `There was a problem creating your account. Please contact customer support at ${customerSupportPhone}.`,
  authentication: 'Authentication failed',
  paypalAuthentication: 'PayPal authentication failed',
  confirmPaypalAccount: (customerSupportPhone) => `PayPal account confirmation failed. Please contact customer support at ${customerSupportPhone}.`,
  securityQuestionsMatchError: 'All questions and answers must be unique',
  resetPasswordErrorMessage: (customerSupportPhone) => `There was a problem resetting your password. Please contact customer support at ${customerSupportPhone}.`,
  forgotPasswordForbidden: (customerSupportPhone) => `Please call customer service to reset your account with our upgraded security at ${customerSupportPhone}`,
  forgotPasswordGeneric: (customerSupportPhone) => `Please try again.\nIf you still can't log in, please call customer support at ${customerSupportPhone}`,
  incorrectSecurityQuestionResponseMessage: 'Incorrect answer',
  verifyEmailError: (customerSupportPhone) => `There was a problem verifying your email. Please contact customer support at ${customerSupportPhone}.`,
  mustSelectLandlordOption: 'Please select Yes or No.',
  routingNumberError: 'This is not a valid routing number.',
  passwordMatch: 'The Passwords must match.',
  enterAnAmount: 'Enter an amount',
  eidlMaxAmount: 'Amount must be less than or equal to $10,000',
  currencyZeroError: 'Enter an amount, or "0" if not applicable',
  chooseYesOrNo: 'Choose Yes or No before continuing',
  mustBeNumber: 'Must be a number',
  requestFailure: 'Request failed. Please try again or contact customer support.',
  dateBoundsError: 'Please select a date that is at least 8 weeks following your loan disbursement date (which is shown to you as the start date in this question) but not more than 24 weeks after your loan disbursement date.',
  cannotBeUsername: 'Cannot be the username.',
  passwordExpired: 'Your password has expired. Please check your email for a link to reset your password.',
  addBankErrorObject: {
    APPLICATION_NOT_FOUND: 'Application not found.',
    WIRE_INVALID_BUSINESS_NAME: 'Your Business name on our records does not match with the Business name in your bank records for deposit account. To proceed, please update the correct Business name.',
    ACH_INVALID_BUSINESS_NAME: 'Your Business name on our records does not match with the Business name in your bank records for payment account. To proceed, please update the correct Business name.',
    WIRE_ROUTING_NUMBER_NOT_FOUND: 'Incorrect Routing Number for deposit account.',
    ACH_ROUTING_NUMBER_NOT_FOUND: 'Incorrect Routing Number for withdrawl account .',
    WIRE_ROUTING_TYPE_MISMATCH: 'The routing type provided doesn’t match the routing type in Fed ACH Directory.',
    ACH_ROUTING_TYPE_MISMATCH: 'The routing type provided doesn’t match the routing type in Fed ACH Directory.',
    INTERNAL_SERVER_ERROR: 'Internal Server error occurred.',
  },
};
